import logo from "./media/image.jpeg";
import mainlogo from "./media/logo.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./App.css";

function App() {
    return (
        <>
            <div style={{ height: "100vh" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        left: 0,
                        right: 0,
                    }}
                >
                    <img
                        src={mainlogo}
                        alt="logo"
                        style={{
                            height: "100px",
                            filter: "drop-shadow(black 0.5px 0.5px 20px)",
                            marginTop: "3%",
                            zIndex: 100,
                        }}
                    />
                </div>
                <img
                    src={logo}
                    alt="galaxy"
                    style={{
                        zIndex: 100,
                        width: "100%",
                        height: "80%",
                        objectFit: "cover",
                        filter: "brightness(50%)",
                    }}
                />
                <div
                    style={{
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        top: "35%",
                        right: 0,
                        left: 0,
                    }}
                >
                    <h1 style={{ color: "white", padding: "0 5%" }}>
                        “The universe is not made up of atoms; it’s made up of
                        tiny stories.”
                    </h1>
                    <p>- Joseph Gordon-Levitt</p>
                </div>

                <div
                    className="footer"
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0 5% 5% 5%",
                    }}
                >
                    <div
                        style={{
                            color: "grey",
                            textAlign: "center",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <p style={{ marginBottom: "0" }}>
                            © 2022 adamas story house
                        </p>
                        <p
                            style={{
                                marginTop: "0",
                                textAlign: "right",
                            }}
                        >
                            Brewed in Bengaluru.
                        </p>
                    </div>
                    <div
                        style={{ color: "white", textAlign: "center", flex: 2 }}
                    >
                        <div>
                            <p
                                style={{
                                    fontSize: "large",
                                    fontWeight: 500,
                                    marginBottom: "1%",
                                    marginTop: "0.6%",
                                }}
                            >
                                Stories from Our Hearts to Yours. Soooooon!
                            </p>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ color: "grey", textAlign: "center" }}>
                            <p style={{ textAlign: "center" }}>Contact us</p>
                            <a
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                }}
                                href="mailto:akshay@adamasstory.com"
                            >
                                <p
                                    style={{
                                        color: "grey",
                                    }}
                                >
                                    Email: akshay@adamasstory.com
                                </p>
                            </a>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: "5%",
                            }}
                        >
                            <a
                                href="https://www.instagram.com/adamasstoryhouse/"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <InstagramIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="https://www.facebook.com/adamasstoryhouse"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <FacebookIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCpC21cASwDXFKASPIZit_0g"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <YouTubeIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="#"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <TwitterIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="#"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <LinkedInIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="footermobile"
                    style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "5%",
                    }}
                >
                    <div
                        style={{ color: "white", textAlign: "center", flex: 2 }}
                    >
                        <div>
                            <p
                                style={{
                                    fontSize: "large",
                                    fontWeight: 500,
                                    marginBottom: "1%",
                                    marginTop: "0.6%",
                                }}
                            >
                                Stories from Our Hearts to Yours. Soooooon!
                            </p>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            justifyContent: "center",
                        }}
                    >
                        <div style={{ color: "grey", textAlign: "center" }}>
                            <p style={{ textAlign: "center" }}>Contact us</p>
                            <a
                                style={{
                                    cursor: "pointer",
                                    textDecoration: "none",
                                }}
                                href="mailto:akshay@adamasstory.com"
                            >
                                <p
                                    style={{
                                        color: "grey",
                                    }}
                                >
                                    Email: akshay@adamasstory.com
                                </p>
                            </a>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: "5%",
                            }}
                        >
                            <a
                                href="https://www.instagram.com/adamasstoryhouse/"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <InstagramIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="https://www.facebook.com/adamasstoryhouse"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <FacebookIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCpC21cASwDXFKASPIZit_0g"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <YouTubeIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="#"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <TwitterIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                            <a
                                href="#"
                                style={{ cursor: "pointer" }}
                                target="_blank"
                            >
                                <LinkedInIcon
                                    fontSize="large"
                                    sx={{ color: "grey" }}
                                />
                            </a>
                        </div>
                    </div>
                    <div
                        style={{
                            color: "grey",
                            textAlign: "center",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <p style={{ marginBottom: "0" }}>
                            © 2022 adamas story house
                        </p>
                        <p
                            style={{
                                marginTop: "0",
                                textAlign: "right",
                            }}
                        >
                            Brewed in Bengaluru.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
